.about__page {
    height: calc(100vh/* - var(--NavBar__Height)*/);
    background-color: var(--BGC__Secondary);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.about__text {
    height: 100%;
    padding: 10px;
    overflow-y: auto;
}

.about {
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
    padding: 10px;
}

.about ul {
    list-style-type: none;
}

@media screen and (max-width: 800px) {

    .about__page {
        padding: 0;
    }

}