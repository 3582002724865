
:root {
    /* Colors / Themes */
    --BGC__Primary: rgba(144, 175, 197, 0.2);
    --BGC__Secondary: rgba(144, 175, 197, 0.4);
    --BGC__Section: rgba(107, 125, 125, 1);
    --Highlight: rgba(166, 216, 212, 1);
    --Icons: rgba(12, 76, 12, 0.7);
    --Text__Primary: rgba(215, 218, 229, 1);
    --Active__Text: rgba(12, 76, 12, 0.8);
    --Btn__BGC: rgba(12, 76, 12, 0.7);
    --NavBar__Display: rgba(107, 125, 125, 0.7);
    
    /* Parameters */
    --NavBar__Height: 55px;

    --Test__Color: rgb(16, 39, 16);
}