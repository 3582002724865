.nav__side__container__display,
.links__side__container__display {
    display: none;
}

.nav__container {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: var(--NavBar__Display);
    height: var(--NavBar__Height);
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: var(--Text__Primary);
}
.nav__social__links,
.nav__links__container
{
    cursor: pointer;
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
}

/*--------------------------------Navigation Links--------------------------------*/
.nav__link
{
    cursor: pointer;
    padding: 7px 25px;
    margin: 0 4px;
    border: none;
    border-radius: 8px;
    background-color: var(--Btn__BGC);
    font-weight: 900;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.active .nav__link
{
    cursor: pointer;
    padding: 7px 25px;
    margin: 0 4px;
    border: none;
    border-radius: 8px;
    background-color: var(--Highlight);
    font-weight: 900;
    color: var(--Active__Text);
}

.active {
    text-decoration: none;
}

.nav__link:hover
{
    background-color: var(--Highlight);
    transition: 500ms;
    color: var(--Active__Text);   
}


/*--------------------------------Social Media Links--------------------------------*/
.nav__social__links {
    display: flex;
    list-style: none;
    width: 25%;
    justify-content: space-around;
    align-items: center;
    font-size: 1.7rem;
    color: var(--Text__Primary);
    
}

.nav__social__links .nav__side__link a:hover {
    color: var(--Highlight);
    transition: 500ms; 
}

.fa-file-pdf,
.fa-linkedin,
.fa-github,
.fa-at,
.fa-phone
{
    color: var(--Icons)
}

.fa-file-pdf:hover,
.fa-linkedin:hover,
.fa-github:hover,
.fa-at:hover,
.fa-phone:hover
{
    color: var(--Highlight);
}

/*--------------------------------MOBILE--------------------------------*/
@media screen and (max-width: 800px) {
    .nav__container {
        display: none;
    }

    .nav__side__container__display,
    .links__side__container__display 
    {
        display: flex;
        background-color: var(--NavBar__Display);
        flex-direction: column;
        align-items: flex-end;
        justify-content: space-between;
        width: 50px;
        height: 50%;
        position: fixed;
        margin: 2px;
        top: 7%;
        right: 5px;
        border-radius: 25px;
    }

    #portrait {
        height: 55px;
        width: auto;
        border-radius: 25px;
        cursor: pointer;
    }

    .links__side__container__display
    {
        position: fixed;
        left: 5px;
    }

    .nav__links__right__side__container,
    .nav__links__left__side__container
    {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        list-style: none;
        align-items: center;
        justify-content: space-around;
        margin: 0;
        padding: 0;
    }

    .nav__links__left__side__container {
        font-size: 1.2rem;
    }

    .nav__side__link,
    .nav__side__link:visited
    {
        color: var(--Icons);
    }

    .nav__side__link:hover,
    .nav__side__link a:hover
    {
        cursor: pointer;
        border-radius: 50px;
        color: var(--Highlight);
        transition: 500ms; 
    }

    .active .nav__side__link
    {
        cursor: pointer;
        border-radius: 15px;
        color: var(--Highlight);
        font-weight: 900;
    }

    .active {
        text-decoration: none;
    }
    
    .fa-file-pdf,
    .fa-linkedin,
    .fa-github,
    .fa-at,
    .fa-phone
    {
        color: var(--Icons)
    }

    .fa-file-pdf:hover,
    .fa-linkedin:hover,
    .fa-github:hover,
    .fa-at:hover,
    .fa-phone:hover
    {
        color: var(--Highlight);
    }
}