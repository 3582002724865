.home__page {
    height: calc(100vh);
    background-color: var(--BGC__Primary);
    padding-top: var(--NavBar__Height);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.introduction__text{
    padding: 10px;
    overflow-y: auto;
}

.introduction {
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
    padding: 10px;
}

li {
    list-style-type: none;
    font-size: 1.5rem;
}

.introduction__text ul {
    list-style-type: none;
}

@media screen and (max-width: 800px) {

    .home__page {
        padding: 0;
    }

    li {
        list-style-type: none;
        font-size: 1rem;
    }

}