@import './components/navbar';
@import './components/home';
@import './components/about';
@import './components/portfolio';
@import './components/contact';
@import '../utils/root.scss';
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700;900&display=swap');

.nav__container {
    z-index: 1;
}

*{
    font-family: 'Roboto', sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    
}

.content__container {
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    border-radius: 3px;
    background-color: var(--BGC__Section);
    width: 70%;
    height: 80%;
    padding: 2px;
    margin-top: 5px;
}

p {
    font-size: 1.5rem;
    line-height: 1.5rem;
    color: var(--Text__Primary);
}

/*  https://stackoverflow.com/questions/38021252/how-to-make-scrollbar-length-less-than-parent-block 
    https://css-tricks.com/custom-scrollbars-in-webkit/
*/

.about__text ::-webkit-scrollbar,
.portfolio__gallery ::-webkit-scrollbar,
.contact__form ::-webkit-scrollbar,
.introduction__text ::-webkit-scrollbar,
.portfolio__gallery__item__description ::-webkit-scrollbar {
    width: 10px;
    background-color: var(--BGC__Secondary);
    border-radius: 50px;
    height: 10px;
    padding: 5% 0;
    margin: 5% 0;
}

.about__text ::-webkit-scrollbar-thumb,
.portfolio__gallery ::-webkit-scrollbar-thumb,
.contact__form ::-webkit-scrollbar-thumb,
.introduction__text ::-webkit-scrollbar-thumb,
.portfolio__gallery__item__description ::-webkit-scrollbar-thumb {
    background-color: var(--Text__Primary);
    border-radius: 10px;
}

#dropdown-item-button {
    background-color: var(--Btn__BGC);
    color: var(--Text__Primary);
    border: none;
    cursor: pointer;
}

h1 {
    padding-top: 20px;
    margin-bottom: 0px;
    color: var(--Active__Text);
}

span {
    font-weight: 700;
    color: var(--Active__Text);
}


@media screen and (max-width: 800px) {
    
    .content__container {
        width: 70%;
        height: 85%;
    }
    p {
        font-size: 1.2rem;
        line-height: 1.2rem;
    }

    .carousel-control-prev,
    .carousel-control-next {
        width: 5% !important;
        border: 5px solid black;
    }
}

