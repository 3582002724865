.portfolio {
    height: calc(100vh/* - var(--NavBar__Height)*/);
    background-color: var(--BGC__Primary);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.portfolio__gallery,
.carousel {
    position: revert;
}

.portfolio__gallery__item {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px;
}

.portfolio__gallery__item__image {
    text-align: center;
}

.portfolio__gallery__item__image {
    height: 20%;
    width: auto;
    margin-bottom: 20px;
    
}

img {
    width: 70%;
    height: auto;
    border-radius: 10px;
    object-fit: cover;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

.portfolio__gallery {
    height: 100%;
}

.portfolio__gallery__item h2{
    color: var(--Active__Text);
    font-size: 2.5rem;
    font-weight: 900;
}

.descritption__text {
    padding: 0 5%;
}
@media screen and (max-width: 800px) {

    .portfolio {
        padding: 0;
    }

    .portfolio__gallery__item__image {
        height: 20%;
        width: auto;
        margin-bottom: 10%;
    }

    img {
        width: 90%;
        height: auto;
        border-radius: 10px;
        object-fit: cover;
    }

    .portfolio__gallery__item {
        padding: 20px;
    }

    .descritption__text {
        padding: 0 2%;
        font-size: 1rem;
    }

}

