.contact {
    height: calc(100vh - var(--NavBar__Height));
    background-color: var(--BGC__Secondary);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.form__item {
    padding: 20px 10% 0;
}

.contact__form__input__field {
    margin: 5px 0 ;
}

.contact__form__submit__btn {
    margin-top: 35px;
}

.form-label {

    font-size: 1.5rem;
    font-weight: 700;
    color: var(--Active__Text);
}

@media screen and (max-width: 800px) {

    .contact {
        height: 100vh;
    }
    
    .contact__form {
        min-height: 70%;
        width: var(--Content__Width__Mobile);
    }


}